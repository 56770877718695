<template>
  <div class="view-wrapper">
    <v-toolbar class="elevation-0">
      {{ $localize('the_list_of_applications_is_empty') }}
    </v-toolbar>
    <v-toolbar v-if="items.length === 0" no-gutters class="h-full elevation-0">
      <v-spacer/>{{$localize('the_list_of_applications_is_empty')}}<v-spacer/>
    </v-toolbar>
    <v-simple-table v-else
                    fixed-header
                    mobile-breakpoint="0"
                    style="height: calc(100vh - 146px)"
                    class="elevation-5"
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center">{{ $localize('company_name') }}</th>
          <th class="text-center">{{ $localize('field_of_activity') }}</th>
          <th class="text-center">{{ $localize('requested_amount_tenge') }}</th>
          <th class="text-center">{{ $localize('the_purpose_of_attracting') }}</th>
          <th class="text-center">{{ $localize('details') }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="( item, rowIdx) in items">
          <tr :key="rowIdx">
            <td class="text-start">
              <v-row class="align-center justify-start">
                <v-avatar
                    class="blue-grey lighten-4 ma-2 my-1 ml-md-16"
                    dark
                    size="40"
                >
                  <v-icon>
                    mdi-clipboard-text
                  </v-icon>
                </v-avatar>
                <div>
                  <div class="subtitle-1">{{ item.company ? item.company.title : '' }}</div>
                  <div class="subtitle-2">{{ $dateTimeFormat(item.date) }}</div>
                </div>
              </v-row>
            </td>
            <td class="text-center">{{ item.company && item.company.activity ? item.company.activity.title : ''}}</td>
            <td class="text-center">{{ $moneyFormat(item.amount) }}</td>
            <td class="text-center">{{$localize(item.attracting)}}</td>
            <td class="text-center">
              <v-menu
                  bottom
                  left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                      v-for="(menu, i) in menuItems"
                      :key="i"
                      link
                      @click="menuClick(menu, item)"
                  >
                    <v-row class="align-center">
                      <v-icon class="mx-2" color="success" small>{{ menu.icon }}</v-icon>
                      <span class="mr-2">{{ $localize(menu.title) }}</span>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <!--            <td class="text-center">-->
            <!--              <v-btn icon @click="openRequest(item.uid)">-->
            <!--                <v-icon>mdi-dots-horizontal-circle-outline</v-icon>-->
            <!--              </v-btn>-->
            <!--            </td>-->
          </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
    <RequestCreditRatingModal :dialog="creditRatingDialog"
                              :request="request"
                              :itemProp="creditRatingItem"
                              @closeCreditRatingModal="closeCreditRatingModal"
    />
  </div>
</template>

<script>
import RequestCreditRatingModal from "./components/requestCreditRatingModal";

export default {
  components: {RequestCreditRatingModal},
  data : () => ({
    items: [],
    menuItems:[
      {title: "preview", icon: 'mdi-eye-outline'},
      {title: "edit", icon: 'mdi-pencil'},
    ],
    loading: false,
    creditRatingDialog: false,
    request: undefined,
    creditRatingItem: undefined
  }),

  watch:{
    loading(val){
      if(val) this.$appUtils.setOverlay(true)
      else  this.$appUtils.setOverlay(false)
    }
  },

  mounted() {
    this.init()
  },

  methods:{
    async init(){
      const res = await this.$fetch.get('api/request/get-process')
      if(res) this.items = res
    },


    menuClick(menu, item){
      switch (menu.title){
        case 'preview' : this.openPreviewCreditRating(item.uid); break;
        case 'edit' : this.openRequest(item.uid); break;
        default : console.log("menu>>")
      }
    },
    openRequest(uid){
      this.$router.push({ name: 'processing-request', params: { uid: uid }})
    },

    async openPreviewCreditRating(uid){
      this.loading = true
      const res = await this.$fetch.get('api/analysis/get?requestUid=' + uid)
      const requestRes = await this.$fetch.get('api/request/get?uid=' + uid)
      if(res && typeof res === 'object' && requestRes) {
        this.creditRatingItem = res
        this.request = requestRes
        this.creditRatingDialog = true
      }else this.$store.dispatch('setError',{ show: true, type: 'warning', message: this.$localize('application_not_processed')})
      this.loading = false
    },

    closeCreditRatingModal(){
      this.creditRatingDialog = false
      this.request = undefined
      this.creditRatingItem = undefined
    }
  }
}
</script>




